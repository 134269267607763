import { useContext } from "react";
import styles from "./Step2.module.css";
import StepComponent from "../../../components/StepComponent/StepComponent";
import WithdrawBal from "../../../assets/Withdraw Balance.webp";
import UserContext from "../../../helpers/Context/user-context";
import MyLoader from "../../../components/MyLoader";
import { WithdrawAPI } from "../../../helpers/APIs/WithdrawAPI";
import { useNavigate } from "react-router-dom";
import AddImage from "../../../assets/Add.webp";

const Step2 = ({
  setSelectedBank,
  setBankId,
  banks,
  bankId,
  bankErrorMessage,
  amount,
  selectedBank,
  setLoading,
  setWithdrawerrorMessage,
  setErrorModal,
  setAddAccountState,
  minAmount,
  maxAmount,
  maxAccounts,
  setRedirectLink,
  setShowOkBtn, loader, loading
}) => {
  const ctx = useContext(UserContext);

  const navigate = useNavigate();
  //submit withdraw function
  const submitWithdraw = async () => {
    if (selectedBank && amount) {
      setLoading(true);
      const x = await WithdrawAPI(
        selectedBank.id,
        amount * 1000,
        selectedBank.account_number
      );
      if (x === "Account Not Activated") {
        setWithdrawerrorMessage(
          "Tài khoản của bạn chưa được kích hoạt vui lòng gửi tiền trước"
        );
      } else if (x === "PLAYER_NOT_ALLOWED_TO_WITHDRAW") {
        setErrorModal(true);
        setWithdrawerrorMessage(
          "Tài khoản của bạn đang tạm khóa chức năng rút tiền, vui lòng liên hệ Chăm sóc khách hàng"
        );
        setShowOkBtn(true)

      } else if (x === "INSUFFICIENT_BALANCE") {
        setErrorModal(true);
        setWithdrawerrorMessage(
          "Bạn không có đủ số dư để thực hiện giao dịch này"
        );
        setShowOkBtn(true)

      } else if (x === "WAIT_PLEASE") {
        setErrorModal(true);
        setWithdrawerrorMessage(
          "Bạn có yêu cầu đang chờ xử lý. Bạn không thể tạo yêu cầu mới khi đang có yêu cầu chờ xử lý. Vui lòng yêu cầu lại sau."
        );
        setShowOkBtn(true)
        setRedirectLink("/profile/transactions");
      } else if (x === "MAKE_DEPOSIT_REQUEST_FIRST") {
        setErrorModal(true);
        setWithdrawerrorMessage("Yêu cầu đặt cọc trước");
        setShowOkBtn(true)
      } else {
        if (x?.status) {
          setLoading(false);
          navigate("/profile/transactions?tab=withdraw");
        } else if (x?.message === "not enough money") {
          setWithdrawerrorMessage("Số dư không đủ");
        }
      }
    }
  };

  return (
    <div className={styles.WithdrawBalanceOverlay}>
      <div className={styles.WithdrawBalanceWrapper}>
        <StepComponent
          image={WithdrawBal}
          step={"Step 2"}
          title={"Chọn ngân hàng"}
        />
        <div className={styles.withdrawOverlay}>
          <div className={styles.BankSectionOverlay}>
            <div className={styles.BankSectionWrapper}>
              {banks && banks?.length > 0
                ? banks?.slice(0, banks?.length).map((bank) => {
                    return (
                      <div
                        className={styles.bankCardWrappwr}
                        key={bank.id}
                        onClick={() => {
                          setBankId(bank.id);
                          setSelectedBank(bank);
                        }}
                      >
                        <div
                          className={`${styles.bankCard} ${
                            bankId == bank.id ? styles.selectedBank : ""
                          }`}
                        >
                          <div className={styles.bankCardContent}>
                            <span>{bank.User_name}</span>
                            <div>{bank.bank_name}</div>
                            <span
                              style={{
                                display: "inline-block",
                                wordBreak: "break-word",
                              }}
                            >
                              {bank.account_number}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : banks == undefined && (
                    <div style={{ width: "100%" }}>
                      <MyLoader />
                    </div>
                  )}

              <>
                {(() => {
                  const arr = [];
                  for (let i = 0; i <= maxAccounts; i++) {
                    arr.push(
                      banks && banks?.length < i && (
                        <div key={i}>
                          <AddBank setAddAccountState={setAddAccountState}  banks={banks} maxAccounts={maxAccounts} loader={loader} />
                        </div>
                      )
                    );
                  }
                  return arr;
                })()}
              </>
            </div>
          </div>
          <span className={styles.bankGuideText}>
            Để Thay đổi hoặc Xóa thông tin ngân hàng vui lòng liên hệ Chăm sóc
            khách hàng
          </span>
          {loading ? <MyLoader /> : 
            <button
              onClick={submitWithdraw}
              className={`${styles.withdrawBtn}
            ${!amount ||
                  amount < minAmount ||
                  amount > maxAmount ||
                  amount > Number(ctx?.userInfo?.balance) * 30 ||
                  !selectedBank ||
                  (amount?.toString()?.includes(".") &&
                    amount?.toString()?.split(".")[1]?.length &&
                    amount?.toString()?.split(".")[1].length > 2)
                  ? styles.disabled
                  : ""
                }
            `}
              disabled={
                !amount ||
                amount < minAmount ||
                amount > maxAmount ||
                amount > Number(ctx?.userInfo?.balance) * 30 ||
                !selectedBank ||
                (amount?.toString()?.includes(".") &&
                  amount?.toString()?.split(".")[1]?.length &&
                  amount?.toString()?.split(".")[1].length > 2)
              }
            >
              nộp
            </button>}
        </div>
      </div>
    </div>
  );
};

export default Step2;

const AddBank = ({ setAddAccountState, banks, maxAccounts, loader }) => {
  return (
    <div
      className={`${styles.AddBankBtnWrapper} ${(banks?.length >= maxAccounts) && styles.disabled
        }`}
      onClick={() => ((banks?.length && banks?.length < maxAccounts && !loader) || (banks?.length == 0 && !loader)) && setAddAccountState(true)}
    >
      <div className={styles.AddBankBtn}>
        <img className={styles.addBankImage} src={AddImage} alt="add" />
        <span>Thêm ngân hàng</span>
      </div>
    </div>
  );
};
