// import { AiOutlineClose } from "react-icons/ai";
import { useState, useEffect } from "react";
import styles from "./SingleTransactionPopup.module.css";
import { APIGetSingleTransaction } from "../../../helpers/APIs/TransactionAPI";
import { addCommasToInput } from "../../../helpers/NumberHelper";
import CopyItem from "../../../components/CopyItem/CopyItem";
import TimeConverter from "../../../helpers/TimeConverter";
import { AiOutlineClose } from "react-icons/ai";
import MyLoader from "../../../components/MyLoader";
import QRCodeComponent from "../../Deposit/QRCodeComponent";
import QRpage from "../../Deposit/QRPage/QRpage";

const SingleTransactionPopup = ({ show, hideModal, transactionId, setSingleTransactionModal, singleTransactionModal }) => {
  const [tranData, setTranData] = useState()
  const [loading, setLoading] = useState(true);
  // get single transaction
  useEffect(() => {
    transactionCall();
  }, []);

  // Single transaction API
  const transactionCall = async () => {
    try {
      const res = await APIGetSingleTransaction(localStorage.getItem('auth_token'), transactionId);
      if (res.status) {
        setTranData(res.data);
        setLoading(false);
      }
    }
    catch (e) {
      setLoading(false)
    }
  };

  // All items
  const items = [
    {
      label: "Ngân hàng nạp tiền",
      value: tranData ? tranData?.bank_name : "Đang tải..", copy: false
    },
    {
      label: "Tên tài khoản nhận",
      value: tranData ? tranData?.bank_account_name : "Đang tải..", copy: false, fontSmall: true
    },
    {
      label: "Số tài khoản",
      value: tranData ? tranData?.bank_account_number && tranData?.bank_account_number?.replace(/\d{4}(?=.)/g, '$& ') : "Đang tải..", copy: true
    },
    { label: "Số tiền nạp", value: tranData ? addCommasToInput(tranData?.amount) : "Đang tải..", copy: true },

    // { label: "Nội dung chuyển khoản", value: tranData ? tranData?.user?.phone : "Đang tải..", copy: true},
    { label: "Ngày", value: tranData ? TimeConverter(new Date(tranData?.transaction_created_time)) : "Đang tải..", copy: false },
    {
      label: "Trạng thái",
      value: tranData ? checkStatus(tranData?.is_approved) : "Đang tải..",
      copy: false
    },
    {
      label: "Nội dung chuyển khoản",
      value: tranData ? tranData?.note : "Đang tải..",
      copy: true,
      redText: true,
    },
  ];



  // add item if transaction status is 2 Rejected
  if (tranData && tranData.is_approved === 2) {
    items.push({ label: "Lý do", value: tranData ? tranData.note : "Đang tải..", redText: true });

  }

  return (
    <div className={styles.singleTransactionContainer}>
      {loading ? (
        <MyLoader />
      ) : tranData?.transaction_purpose === "deposit" &&
        tranData?.is_redirect &&
        tranData?.is_approved === 0 ? (
        <div className={styles.singleTranContentOverlay}>
          <div className={styles.singleTranContentWrapper}>
            <QRpage singleTransactionModal={singleTransactionModal} setSingleTransactionModal={setSingleTransactionModal} data={tranData?.payment_url} transactionId={tranData?.transaction_id} result={tranData} />
          </div>
        </div>
      ) : tranData?.transaction_purpose === "deposit" &&
        !tranData?.is_redirect &&
        tranData?.is_approved === 0 ? (<>
          <div className={styles.singleTranContentOverlay}>
            <div className={styles.singleTranContentWrapper}>
              {/* <div className={styles.titleWrapper}>
                <h3>Giao dịch</h3>
                <span className={styles.closeIcon}>
                  <AiOutlineClose className={styles.icon} onClick={hideModal} />
                </span>
              </div> */}
              <form
                style={{ display: "flex", flexDirection: "column", minHeight: "100%", gap: 20, height: 'auto', width: '100%' }}
              >
                <div className={styles.section}>
                  <div className={styles.depositCard} >
                    <QRCodeComponent singleTransactionModal={singleTransactionModal} setSingleTransactionModal={setSingleTransactionModal} transactionId={tranData?.transaction_id} base64String={tranData?.payment_qr} QRData={tranData} />
                    {items.map((item, index) => (
                      <CopyItem key={index} item={item} />
                    ))}

                    <div
                      style={{
                        textAlign: "center",
                        padding: "0px 5px",
                        fontSize: "12px",
                        color: "white",
                      }}
                      className={styles.bottomSection}
                    >
                      <p>
                        Lưu ý: Điền chính xác mã{" "}
                        <strong style={{ color: "#F93B3B", fontSize: "14px" }}>
                          "{tranData ? tranData?.note : ""}"
                        </strong>
                        vào mục "Nội dung/Lời nhắn"
                      </p>
                      <p>
                        Thiếu NỘI DUNG CHUYỂN KHOẢN, CHÚNG TÔI sẽ không thể nhận ra
                        khoản chuyển từ Quý khách
                      </p>
                    </div>
                    {loading ? (
                      <div className={styles.loader}>
                        {" "}
                        <MyLoader />
                      </div>
                    ) : (
                      ""
                    )}


                  </div>
                </div>
              </form>
            </div>
          </div>

        </>) : (


        <div className={styles.singleTranContentOverlay}>
          <div className={styles.singleTranContentWrapper}>
            <div className={styles.titleWrapper}>
              <h3>Giao dịch</h3>
              <span className={styles.closeIcon}>
                <AiOutlineClose className={styles.icon} onClick={hideModal} />
              </span>
            </div>
            {items.map((item, index) => (
              <CopyItem key={index} item={item} />
            ))}
          </div>
        </div>
      )}

    </div>
  );
};

export default SingleTransactionPopup;

// check Transaction Status
const checkStatus = (status) => {
  if (status === 0) {
    return "Đang chờ";
  } else if (status === 1) {
    return "Đã phê duyệt";
  } else if (status === 2) {
    return "Từ chối";
  } else if (status === 3) {
    return "Đang xử lý";
  } else if (status === 4) {
    return "Đang xử lý";
  } else {
    return "Từ chối";
  }
};
