import Modal from "@mui/material/Modal";
import styles from "./PopupErrorModal.module.css";
import errorIcon from "../../assets/Error.webp";
import successIcon from "../../assets/Success.webp";
import { useNavigate } from "react-router";


const PopupErrorModal = ({
  message,
  show,
  hideModal,
  error = true,
  blockDeposit = false,
  blockWithdraw = false,
  redirectLink,
  showOkBtn = false
}) => {
  const navigate = useNavigate();

  return (
    <Modal open={show ? true : false} >
      <div
        className={styles.modalOverlay}
       
      >
        <div
          className={styles.loadingSection}
          // onClick={(e) => e.stopPropagation()}
        >
          {error ? (
            <img src={errorIcon} className={styles.errorImg} alt="errorImg" />
          ) : (
            <img
              src={successIcon}
              className={styles.errorImg}
              alt="successImg"
            />
          )}
          <div className={styles.messageText}>{message}</div>

          {blockDeposit || blockWithdraw || showOkBtn ? (
            <div className={styles.rejectButtons}>
             

              {blockDeposit || blockWithdraw ? (
                <button
                  className={`${styles.okButton} ${styles.csBtn}`}
                  onClick={() => window.open("https://direct.lc.chat/13775445/")}
                >
                  Hỗ trợ khách hàng
                </button>
              ) : (
                ""
              )}
              <button className={styles.okButton} onClick={() => {
                navigate(`${redirectLink}`)
                hideModal();
              }}>
                OK
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PopupErrorModal;
