export default function TimeConverter(serverdate) {
    try {
        // Check if serverDate is valid and convert to date object
        const dateObject = new Date(serverdate);
        if (isNaN(dateObject.getTime())) {
            throw new Error('Invalid date');
        }

        // Convert date to UTC string without milliseconds
        const utcDateWithoutMillis = dateObject.toISOString().slice(0, -5) + "Z";

        // Create a new Date object from the UTC string
        const utcDate = new Date(utcDateWithoutMillis);

        // Calculate local time considering timezone offset
        const offsetMinutes = utcDate.getTimezoneOffset();
        const localTime = new Date(utcDate.getTime() - offsetMinutes * 60 * 1000);
        const localTimeString = localTime.toLocaleString();

        return localTimeString;
    } catch (error) {
        console.error('Time conversion error:', error);
        return '-'; // Return dash if there is an error
    }

}