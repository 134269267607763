import style from "../../components/HottestSection/HottestSection.module.css";
import DividerLeft from "../../assets/HomeImages/DividerLeft.webp";
import DividerRight from "../../assets/HomeImages/DividerRight.webp";
import Game1 from "../../assets/HomeImages/Section1/Casino.webp";
import Game2 from "../../assets/HomeImages/Section1/Fish.webp";
import DagaTitle from "../../assets/HomeImages/Section1/DagaTitle.webp";
import { useContext } from "react";
import UserContext from "../../helpers/Context/user-context";
import { useNavigate } from "react-router-dom";
import DagaErrorModal from "../HomeComponents/DagaErrorModal";
import { useDagaGame } from "../../helpers/useDagaGame";

export const HottestSection = () => {
  const { transferAllPointsInDaga, loading, showErrorModal, setShowErrorModal } = useDagaGame();

  const ctx = useContext(UserContext);
  const navigate = useNavigate();

  const referral = ctx.refCode && ctx.refCode 
 

  const gameImgClick = () => {
    window.open(ctx.refCode ? `https://${referral}.sv368vn.today/register.html` : `https://www.sv368.bio/register`, '_blank')

  }
  return (
    <div className={style.wrapper}>
      <div className={style.sectionTitle}> 
        <div><img
          className={style.imgDivider}
          src={DividerLeft}
          alt={`DividerLeft`}
        /></div>
        
        <div className={style.title}>Hot Nhất</div>
        <div>
        <img
          className={style.imgDivider}
          src={DividerRight}
          alt={`DividerRight`}
        />
        </div>
      </div>
      <div className={style.sectionContent}>
        <div className={style.leftContent}>
          <div className={style.dagaSection}>
            <img
              className={style.dagaTitleImage}
              src={DagaTitle}
              alt={`DagaTitle`}
            />
            <span className={style.dagaDescription}>
              Lorem ipsum dolor sit amet, conset sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore
            </span>
            <button className={style.dagaButton} style={{ cursor: 'pointer' }} onClick={() => loading || transferAllPointsInDaga()}
>
              <span className={style.dagaButtonText}>Chơi Ngay</span>
            </button>
          </div>
        </div>
        <div className={style.rightContent}>
          <img className={style.hottestGameImage} src={Game1} alt={`Game1`} onClick={gameImgClick} />
          <img className={style.hottestGameImage} src={Game2} alt={`Game2`} onClick={gameImgClick} />
        </div>
      </div>
      {showErrorModal && (
        <DagaErrorModal
          showErrorModal={showErrorModal}
          setShowErrorModal={setShowErrorModal}
        />
      )}
    </div>
  );
};

export default HottestSection;
