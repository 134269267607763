import React from "react";
import { Modal } from "@mui/material";
import dagaErrorImg from "../../assets/dagaErrorImage/no_user_id_image.webp";
import styles from "./DagaErrorModal.module.css";
import { IoMdCloseCircleOutline } from "react-icons/io";

const DagaErrorModal = ({ showErrorModal, setShowErrorModal }) => {
  return (
    <Modal
      open={showErrorModal ? true : false}
      className={styles.modalTopOverlay}
      onClose={() => setShowErrorModal(false)}
    >
      <div className={styles.wrapper}>
        <img src={dagaErrorImg} alt="error image1"  />

        <span
          className={styles.crossIcon}
          onClick={() => {
            setShowErrorModal(false);
          }}
        >
          <IoMdCloseCircleOutline size={45} color={"white"} />
        </span>
      </div>
    </Modal>
  );
};

export default DagaErrorModal;
