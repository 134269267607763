import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import styles from "./SecondaryHeader.module.css";
import Agency from "../../../../assets/HeaderIcons/Agency.webp";
import HotTag from "../../../../assets/HeaderIcons/hot.webp";
import UserContext from "../../../../helpers/Context/user-context";
import { getGameCategories } from "../../../../helpers/APIs/GameAPIs";
import MyLoader from "../../../MyLoader";
import DagaSection from "../DagaSection/DagaSection";
import ShowGames from "./ShowGames/ShowGames";
import SiteLogo from "../../../../assets/NewHomeIcons/Header2Icons/SV368 Logo.webp";
import Daga from "../../../../assets/NewHomeIcons/Header2Icons/DAGA.webp";
import DagaIcon from "../../../../assets/NewHomeIcons/Header2Icons/DagaIcon.webp";
import LiveCasino from "../../../../assets/NewHomeIcons/Header2Icons/Live Casino.webp";
import Sport from "../../../../assets/NewHomeIcons/Header2Icons/Sport.webp";
import Lottery from "../../../../assets/NewHomeIcons/Header2Icons/Lottery.webp";
import Game from "../../../../assets/NewHomeIcons/Header2Icons/GAME.webp";
import Fish from "../../../../assets/NewHomeIcons/Header2Icons/Fish.webp";
import LoginButtons from "../LoginButtons/LoginButtons";
import BalanceSection from "../BalanceSection/BalanceSection";
import LogoutImg from "../../../../assets/NewHomeIcons/Header2Icons/Logout.webp";
import Balance from "../../../../assets/NewHomeIcons/Header2Icons/Balance.webp";
import LogoutConfirmationModal from "../../../LogoutConfirmationModal/LogoutConfirmationModal";
import CommaSeperator from "../../../CommaSeperator";

//Asset
import C1 from "../../../../assets/Categories/1- Casino/1.webp";
import C2 from "../../../../assets/Categories/1- Casino/2.webp";
import C3 from "../../../../assets/Categories/1- Casino/3.webp";
import C4 from "../../../../assets/Categories/1- Casino/4.webp";
import C5 from "../../../../assets/Categories/1- Casino/5.webp";
import C6 from "../../../../assets/Categories/1- Casino/6.webp";
import C7 from "../../../../assets/Categories/1- Casino/7.webp";
import C8 from "../../../../assets/Categories/1- Casino/8.webp";
import C9 from "../../../../assets/Categories/1- Casino/9.webp";
import S1 from "../../../../assets/Categories/2- Sport/1.webp";
import S2 from "../../../../assets/Categories/2- Sport/2.webp";
import S3 from "../../../../assets/Categories/2- Sport/3.webp";
import S4 from "../../../../assets/Categories/2- Sport/4.webp";
import S5 from "../../../../assets/Categories/2- Sport/5.webp";
import S6 from "../../../../assets/Categories/2- Sport/6.webp";
import L1 from "../../../../assets/Categories/3- Lottery/1.webp";
import L2 from "../../../../assets/Categories/3- Lottery/2.webp";
import L3 from "../../../../assets/Categories/3- Lottery/3.webp";
import CG1 from "../../../../assets/Categories/4- Card Game/1.webp";
import CG2 from "../../../../assets/Categories/4- Card Game/2.webp";
import CG3 from "../../../../assets/Categories/4- Card Game/3.webp";
import CG4 from "../../../../assets/Categories/4- Card Game/4.webp";
import F1 from "../../../../assets/Categories/5- Fishing/1.webp";
import F2 from "../../../../assets/Categories/5- Fishing/2.webp";
import F3 from "../../../../assets/Categories/5- Fishing/3.webp";
import F4 from "../../../../assets/Categories/5- Fishing/4.webp";
import F5 from "../../../../assets/Categories/5- Fishing/5.webp";
import F6 from "../../../../assets/Categories/5- Fishing/6.webp";
import { APIDagaWithdraw } from "../../../../helpers/APIs/DagaAPI";
import { useDagaGame } from "../../../../helpers/useDagaGame";
import DagaErrorModal from "../../../HomeComponents/DagaErrorModal";
// import DownloadIcon from "../../../../assets/HeaderIcons/DownloadIcon.webp";
// import { AttentionSeeker } from "react-awesome-reveal";

const SecondaryHeader = ({ secondHeaderData }) => {
  const [gameCategories, setGameCategories] = useState();
  const [showGames, setShowGames] = useState(false);
  const [gameTransactionError, setGameTransactionError] = useState("");
  const [games, setGames] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [selected, setSelected] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const navigate = useNavigate();
  const ctx = useContext(UserContext);
  const token = localStorage.getItem("auth_token");
  const {
    transferAllPointsInDaga,
    getDagaBalance,
    loading,
    dagaLoader,
    setDagaLoader,
    showErrorModal,
    setShowErrorModal,
  } = useDagaGame();

  useEffect(() => {
    if (token) {
      getDagaBalance();
    }
    // }
  }, [token]);

  const referral = ctx.refCode && ctx.refCode;

  // use Query to get data from DB
  const { data, isLoading, error } = useQuery("categories", getGameCategories, {
    refetchOnWindowFocus: false,
  });

  const gameImgClick = () => {
    window.open(
      ctx.refCode
        ? `https://${referral}.sv368vn.today/register.html`
        : `https://www.sv368.bio/register`,
      "_blank"
    );
  };

  // set category games data in context, manage loader
  useEffect(() => {
    // Once the data is loaded, update the state
    if (data) {
      setGameCategories(data?.games);
      ctx.setCategoryGamesContext(data);
    }

    if (isLoading) {
    }

    // Handle errors if any
    if (error) {
      console.error("Error fetching data:", error);
    }
  }, [data, isLoading, error]);

  // Games length checks, set error message
  useEffect(() => {
    if (gameCategories?.games?.length === 0) {
      setErrorMsg("Không tìm thấy trò chơi nào");
    } else if (gameCategories === "NETWORK_ERROR") {
      setErrorMsg("Lỗi mạng");
    }
  }, [gameCategories]);

  // Category hover function
  const handleHover = (id) => {
    setSelected(id);

    setGames(
      gameCategoriesForTesting?.filter((category) => {
        return Number(category.id) === Number(id);
      })[0].game_items
    );
  };

  // Daga Withdraw api
  const dagaWithdraw = async () => {
    if (ctx.dagaBalance < 1) {
      setGameTransactionError("Số dư tối thiểu phải là 1");
    } else {
      setDagaLoader(true);
      const dagaPoints = Math.floor(ctx.dagaBalance);
      const res = await APIDagaWithdraw(dagaPoints);
      if (res === "FORBIDDEN") {
        setGameTransactionError("CẤM");
      } else if (res === "LACK_FUND") {
        setGameTransactionError("Thiếu vốn");
      } else if (res === "NETWORK_ERROR") {
        setGameTransactionError("LỖI MẠNG");
      } else if (res === "WITHDRAW_LIMIT_OVER") {
        setGameTransactionError(
          "Tính năng rút tiền tạm đóng vì bạn đã có 1 giao dịch rút tiền hôm nay. Vui lòng thực hiện lại sau 00:00 hoặc liên hệ chăm sóc khách hàng"
        );
      } else if (res?.status) {
        ctx.setDagaBalance(ctx.dagaBalance - dagaPoints);
        ctx.setUserInfo({
          ...ctx.userInfo,
          balance: res.player_balance,
        });
      }
      setDagaLoader(false);
    }
  };

  const gameCategoriesForTesting = [
    {
      id: 1,
      name: '{"en":"CASINO","vn":"SÒNG BÀI"}',
      game_items: [
        {
          id: 1,
          name: '{"en":"Game Name","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: C1,
        },
        {
          id: 2,
          name: '{"en":"Game Name","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: C2,
        },
        {
          id: 3,
          name: '{"en":"Game Name","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: C3,
        },
        {
          id: 4,
          name: '{"en":"Game Name","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: C4,
        },
        {
          id: 5,
          name: '{"en":"Game Name","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: C5,
        },
        {
          id: 6,
          name: '{"en":"Game Name","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: C6,
        },
        {
          id: 7,
          name: '{"en":"Game Name","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: C7,
        },
        {
          id: 8,
          name: '{"en":"Game Name","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: C8,
        },
        {
          id: 9,
          name: '{"en":"Game Name","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: C9,
        },
      ],
    },
    {
      id: 2,
      name: '{"en":"SPORTS","vn":"THỂ THAO"}',
      game_items: [
        {
          id: 1,
          name: '{"en":"SPORT Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: S1,
        },
        {
          id: 2,
          name: '{"en":"SPORT Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: S2,
        },
        {
          id: 3,
          name: '{"en":"SPORT Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: S3,
        },
        {
          id: 4,
          name: '{"en":"SPORT Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: S4,
        },
        {
          id: 5,
          name: '{"en":"SPORT Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: S5,
        },
        {
          id: 6,
          name: '{"en":"SPORT Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: S6,
        },
      ],
    },
    {
      id: 3,
      name: '{"en":"LOTTERY","vn":"XỔ SỐ"}',
      game_items: [
        {
          id: 1,
          name: '{"en":"Lottery Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: L1,
        },
        {
          id: 2,
          name: '{"en":"Lottery Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: L2,
        },
        {
          id: 3,
          name: '{"en":"Lottery Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: L3,
        },
      ],
    },
    {
      id: 4,
      name: '{"en":"Card","vn":"GAME BÀI"}',
      game_items: [
        {
          id: 1,
          name: '{"en":"Card Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: CG1,
        },
        {
          id: 2,
          name: '{"en":"Card Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: CG2,
        },
        {
          id: 3,
          name: '{"en":"Card Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: CG3,
        },
        {
          id: 4,
          name: '{"en":"Card Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: CG4,
        },
      ],
    },
    {
      id: 5,
      name: '{"en":"Casino","vn":"BÁN CÁ"}',
      game_items: [
        {
          id: 1,
          name: '{"en":"Casino Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: F1,
        },
        {
          id: 2,
          name: '{"en":"Casino Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: F2,
        },
        {
          id: 3,
          name: '{"en":"Casino Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: F3,
        },
        {
          id: 4,
          name: '{"en":"Casino Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: F4,
        },
        {
          id: 5,
          name: '{"en":"Casino Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: F5,
        },
        {
          id: 6,
          name: '{"en":"Casino Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: F6,
        },
      ],
    },
  ];

  return (
    <>
      <div className={styles.secondaryHeaderOverlay}>
        <div className={styles.siteLogo} onClick={() => navigate("/")}>
          <img
            className={styles.siteLogoImage}
            src={SiteLogo}
            alt={"SiteLogo"}
          />
        </div>
        <div className={styles.gamesNavigationList}>
          <div
            className={`${styles.gameDaga} ${styles.active}`}
            onClick={() => loading || transferAllPointsInDaga()}
          >
            <img className={styles.gameDagaImage} src={Daga} alt={"Daga"} />
            {/* <div className={styles.gameTitleContainer}>
              <span className={styles.gameTitleDaga}>DAGA</span>
            </div> */}
          </div>
          {
            gameCategoriesForTesting?.map(
              (item) =>
                JSON.parse(item?.name).vn !== "Daga" && (
                  <div
                    className={styles.game}
                    key={item.id}
                    // onClick={gameImgClick}
                    onMouseEnter={() => {
                      setShowGames(true);
                      handleHover(item.id);
                    }}
                    onMouseLeave={() => {
                      // setSelected(null);
                      setShowGames(false);
                    }}
                  >
                    {/* <img className={styles.gameImage} src={LiveCasino} alt={"LiveCasino"} /> */}
                    <span className={styles.gameTitle}>
                      {JSON.parse(item?.name).vn}
                    </span>
                  </div>
                )
            )}
        </div>
        <div className={styles.loginButton}>
          {token ? (
            !dagaLoader ? (
              <div className={styles.transferSection}>
                <img
                  className={styles.iconSize2}
                  src={DagaIcon}
                  alt="Balance"
                />
                <span
                  className={`${
                    ctx.dagaBalance === "FORBIDDEN" ||
                    ctx.dagaBalance === "NETWORK_ERROR"
                      ? styles.balanceError
                      : styles.balance
                  }`}
                >
                  {ctx.dagaBalance === "NETWORK_ERROR"
                    ? "LỖI MẠNG"
                    : ctx.dagaBalance === "FORBIDDEN"
                    ? "CẤM"
                    : // : CommaSeperator(+ctx.dagaBalance)
                    ctx.dagaBalance > 0
                    ? CommaSeperator(ctx.dagaBalance)
                    : ctx.dagaBalance}
                </span>

                <div>
                  {ctx.dagaBalance > 0 && (
                    <button
                      className={styles.transferButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        dagaWithdraw();
                      }}
                    >
                      Chuyển ví
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <MyLoader size={16} />
            )
          ) : (
            <></>
          )}

          {Number(ctx?.userInfo?.balance) >= 0 && (
            <div className={styles.balanceSection}>
              <img className={styles.iconSize2} src={Balance} alt="Balance" />
              <span>
                {ctx.userInfo?.balance &&
                  `${CommaSeperator(Number(ctx.userInfo?.balance).toFixed(1))}`}
              </span>
            </div>
          )}

          {token ? (
            <div
              className={styles.logoutBtn}
              onClick={() => setLogoutModal(true)}
            >
              <img
                className={styles.iconSize}
                src={LogoutImg}
                alt="logoutIcon"
              />
              Đăng xuất
            </div>
          ) : (
            <LoginButtons />
          )}

          {logoutModal && (
            <LogoutConfirmationModal
              logoutModal={logoutModal}
              closeLogoutModal={() => setLogoutModal(false)}
            />
          )}
        </div>
      </div>
      {showGames && (
        <ShowGames
          showGames={showGames}
          setShowGames={setShowGames}
          games={games}
          errorMsg={errorMsg}
          setSelected={setSelected}
          selected={selected}
          setGames={setGames}
          gameImgClick={gameImgClick}
        />
      )}
      {showErrorModal && (
        <DagaErrorModal
          showErrorModal={showErrorModal}
          setShowErrorModal={setShowErrorModal}
        />
      )}
    </>
  );
};

export default SecondaryHeader;
