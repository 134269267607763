import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./ProfileLayout.module.css";
import ActiveProfile from "../../assets/ProfilePage/NewProfileIcons/Active/Active - Profile.webp";
import InActiveProfile from "../../assets/ProfilePage/NewProfileIcons/Inactive/Inactive - Profile.webp";
import ActiveDeposit from "../../assets/ProfilePage/NewProfileIcons/Active/Active - Deposit.webp";
import InActiveDeposit from "../../assets/ProfilePage/NewProfileIcons/Inactive/Inactive - Deposit.webp";
import ActiveWithdraw from "../../assets/ProfilePage/NewProfileIcons/Active/Active - Withdraw.webp";
import InActiveWithdraw from "../../assets/ProfilePage/NewProfileIcons/Inactive/Inactive - Withdraw.webp";
import ActiveTransactions from "../../assets/ProfilePage/NewProfileIcons/Active/Active - Transaction.webp";
import InActiveTransactions from "../../assets/ProfilePage/NewProfileIcons/Inactive/Inactive - Transaction.webp";
import ActiveBankManagement from "../../assets/ProfilePage/NewProfileIcons/Active/Active - Bank Management.webp";
import InActiveBankManagement from "../../assets/ProfilePage/NewProfileIcons/Inactive/Inactive - Bank Management.webp";
import { useContext } from "react";
import UserContext from "../../helpers/Context/user-context";
import ActiveResetPassword from "../../assets/ProfilePage/Active Reset Password.png";
import InActiveResetPassword from "../../assets/ProfilePage/Inactive Reset Password.png";
const pageOptions = [
  {
    name: "Thông tin tài khoản",
    link: "/profile",
    activeImg: ActiveProfile,
    inActiveImg: InActiveProfile,
  },
  {
    name: "Nạp tiền",
    link: "/profile/deposit",
    activeImg: ActiveDeposit,
    inActiveImg: InActiveDeposit,
  },
  {
    name: "Rút Tiền",
    link: "/profile/withdraw",
    activeImg: ActiveWithdraw,
    inActiveImg: InActiveWithdraw,
  },
  {
    name: "Giao dịch",
    link: "/profile/transactions",
    activeImg: ActiveTransactions,
    inActiveImg: InActiveTransactions,
  },
  {
    name: "Quản lý ngân hàng",
    link: "/profile/bank-account-management",
    activeImg: ActiveBankManagement,
    inActiveImg: InActiveBankManagement,
  },
  {
    name: "Đặt lại mật khẩu",
    link: "/profile/reset-password",
    activeImg: ActiveResetPassword,
    inActiveImg: InActiveResetPassword,
  },
];

const ProfileLayout = () => {
  const ctx = useContext(UserContext);
  const location = useLocation();
  const getLastPathSegment = (path) => {
    const segments = path.split("/");
    let lastSegment = segments.pop() || segments.pop(); // Handle trailing slashes
    lastSegment = lastSegment.replace(/-/g, " "); // Replace all hyphens with spaces
    return lastSegment;
  };
  const formattedLastSegment = getLastPathSegment(location?.pathname);
  function processFormattedLastSegment(formattedLastSegment) {
    switch (formattedLastSegment) {
      case "profile":
        return "Tài Khoản";
      case "deposit":
        return "Nạp tiền";
      case "withdraw":
        return "Rút tiền";
      case "transactions":
        return "giao dịch";
      case "bank account management":
        return "quản lý tài khoản ngân hàng";
      default:
        return "";
    }
  }
  return (
    <div className={styles.profileLayoutOverlay}>
      <div className={styles.profileLayoutWrapper}>
        <div className={styles.profileLayoutHeader}>
          {/* {ctx.user && ctx?.userInfo?.name ? (
            <span>Chào mừng: {ctx?.userInfo?.name}</span>
          ) : (
            <MyLoader />
          )}
          {ctx.user && ctx?.userInfo?.created_at ? (
            <span className={styles.joinedDate}>
              Ngày tham gia:{" "}
              {new Date(ctx?.userInfo?.created_at).toLocaleDateString()}
            </span>
          ) : (
            <MyLoader />
          )} */}
        </div>
        <div className={styles.profileLayoutBody}>
          <PageOptionsComponent pageOptions={pageOptions} />
          <div className={styles.profileLayoutMainOverlay}>
            <div className={styles.profileLayoutMainWrapper}>
              <div className={styles.pageTitle}>
                {processFormattedLastSegment(
                  formattedLastSegment
                ).toUpperCase()}
              </div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileLayout;

// Page options component
const PageOptionsComponent = ({ pageOptions }) => {
  const navigate = useNavigate();
  const activePage = window.location.pathname;
  const { pathname } = useLocation();

  return (
    <div className={styles.profileLayoutAside}>
      {pageOptions.map((item) => (
        <div
          className={`${styles.pageOption} ${
            pathname == item.link ? styles.activePage : ""
          }`}
          onClick={() => navigate(item.link)}
          key={item.name}
        >
          <span
            className={`${styles.pageOptionSide} ${
              activePage === item.link && styles.activePageOption
            }`}
          ></span>
          <div className={styles.pageOptionContainer}>
            <img
              className={styles.pageOptionIcons}
              src={activePage === item.link ? item.activeImg : item.inActiveImg}
              alt="img"
            />
            <span
              className={`${styles.pageOptionName} ${
                activePage === item.link && styles.activePage
              }`}
            >
              {item.name}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
