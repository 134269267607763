import styles from "./Footer1.module.css";
import Promotion from "../../../assets/NewHomeIcons/Header1Icons/Promotion icon.webp";
import Support from "../../../assets/NewHomeIcons/Header1Icons//Support Icon.webp";
import { useNavigate } from "react-router-dom";

const Footer1 = ({mainFooterLinksList, gameImgClick}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.sponsorsWrapper}>
      <div className={styles.gameList} onClick={gameImgClick}>
      {mainFooterLinksList && mainFooterLinksList.map((item)=><div key={item.id}>
        <span className={styles.gameListText} style={{marginRight:5}}>{item?.title}</span>
        <span className={styles.gameListText} >|</span>
        </div>
        )}
      </div>
      <div className={styles.otherDetails}>
        <div className={styles.otherDetailsList} style={{ cursor: 'pointer' }} onClick={() => navigate('/promotions')}>
          <img
            className={styles.otherDetailsImage}
            src={Promotion}
            alt={`Promotion`}
          />
          Khuyến mãi
        </div>
        <span className={styles.gameListText}>|</span>
        <div className={styles.otherDetailsList} style={{ cursor: 'pointer' }} onClick={() => window.open('https://direct.lc.chat/13775445/', '_blank')}>
          <img
            className={styles.otherDetailsImage}
            src={Support}
            alt={`Support`}
          />
          Hỗ Trợ 24/7
        </div>
      </div>
    </div>
  );
};

export default Footer1;
