import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { singleTransactionStatusCheck } from '../../../helpers/APIs/TransactionAPI';
import styles from './QRpage.module.css';
import { useEffect } from 'react';


const QRpage = ({ data = "", transactionId, result = {}, setSingleTransactionModal, singleTransactionModal }) => {
    const navigate = useNavigate();

    // Function to call your API
    const checkTransactionStatus = async () => {
        const res = await singleTransactionStatusCheck(transactionId);
        if (res.status) {
            if (setSingleTransactionModal) {
                setSingleTransactionModal(false);
            } else {
                navigate('/profile/transactions')
            }
        }
    };
    useEffect(() => {
        if (result?.is_redirect) {
            // Call the API on component mount
            checkTransactionStatus();
            // Set up an interval to call the API every 10 seconds
            const intervalId = setInterval(checkTransactionStatus, 10000);
            // Clear the interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, []); // Empty dependency array to ensure this runs once on mount


    return (
        <div className={styles.leftSection}>
            <button
                className={styles.CloseBtn}
                onClick={() => {
                   singleTransactionModal && setSingleTransactionModal(false)
                    navigate("/profile/transactions")
                }}
            >
                Đóng
            </button>
            <iframe
                src={data}
                title="Embedded Page"
                frameBorder="0"
                allowFullScreen
                className={styles.responsiveIframe}
            ></iframe>
        </div>
    )
}


export default QRpage