// import axios from "axios";

// const BaseUrl = axios.create({
//   // staging gasv388 base URL
//   // baseURL: "https://staging.gasv388.net/api",

//   // master gasv388 base URL
//   // baseURL: "https://master.gasv388.net/api",

//   // baseURL: "https://0293ie0w0e8.com/api",
//   // gasv388 base URL Live
//   baseURL: "https://bo.vaosv368.com/api",
// });

// export default BaseUrl;
import axios from "axios";
let axiosInstance = null;

// Function to get the base URL from another API
async function fetchBaseURL() {
  try {
    const response = await axios.get("https://cdntracker0019.com?site_code=sv368");
    if (response?.status === 200 && response.data?.url) {
      return response.data.url;
    } else {
      throw new Error("Invalid response for base URL");
    }
  } catch (error) {
    console.error("Error fetching base URL:", error);
    throw error;
  }
}

async function getAxiosInstance() {
  if (!axiosInstance) {
    try {
      const baseURL = await fetchBaseURL();
      axiosInstance = axios.create({
        baseURL: `${baseURL}/api`,
        base: baseURL,
      });
    } catch (error) {
      console.error("Error creating Axios instance:", error);
      throw error;
    }
  }
  return axiosInstance;
}

export default getAxiosInstance;

