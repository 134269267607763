import React, { useContext } from "react";
import styles from "./Step1.module.css";
import StepComponent from "../../../components/StepComponent/StepComponent";
import PointsConverter from "../../../components/PointsConverter/PointsConverter";
import MyLoader from "../../../components/MyLoader";
import TransferPoints from "../../../assets/Transfer Points.webp";
import AddImage from "../../../assets/Add.webp";
import PointsArrow from "../../../assets/PointsArrow.webp";
import InputTooltipV2 from "../../../components/InputTooltipV2/InputTooltipV2";
import CommaSeperator from "../../../components/CommaSeperator";
import UserContext from "../../../helpers/Context/user-context";
import { useNavigate } from "react-router-dom";

const Step1 = ({ amount,
  setAmount,
  withdrawerrorMessage,
  loading,
  banks,
  selectedBank,
  isWithdraw,
  setLoading,
  setWithdrawerrorMessage,
  setErrorModal,
  loader,
  minAmount, maxAmount,
  setRedirectLink,
  setShowOkBtn }) => {
  const ctx = useContext(UserContext);
  const navigate = useNavigate();

  const onWithdrawAmountChange = (val) => {
    if (!isNaN(val)) {
      setAmount(val);
    }
  };
  return (
    <div className={styles.SelectBankOverlay}>
      <div className={styles.SelectBankWrapper}>
        <StepComponent image={TransferPoints} step={"Step 1"} title={"Số dư"} />
        <div className={styles.withdrawalContainer}>
          <div className={styles.balanceContainer}>
            <span className={styles.balanceTitle}>Số tiền hiện tại:</span>
            <div className={styles.balanceLatest}>
              <span className={styles.balanceText}>{`${CommaSeperator(
                Number(ctx.userInfo?.balance).toFixed(1)
              ) }`}</span>
              <img src={PointsArrow} alt="arrow" />
              <span className={styles.balanceText}>đ {`${CommaSeperator(Number(ctx.userInfo?.balance) * 30)}`}</span>
            </div>
          </div>

          <div className={styles.inputDeposit}>
            <InputTooltipV2
              amount={amount}
              setAmount={setAmount}
              title={"Số điểm nạp"}
              withdraw={true}
              withdrawMinAmount={minAmount}
              withdrawMaxAmount={maxAmount}
              onAmountChange={onWithdrawAmountChange}
            />
          </div>

          <PointsConverter transferPoints={amount * 1000}
            withdraw={true}
            text="Thực tế"

          />
          <div className={styles.minMaxContainer}>
            <div className={styles.minMaxSection}>
              <span className={styles.minMaxText}>Min: </span>
              <span className={styles.minMaxValue}>{CommaSeperator(Number(minAmount))}</span>
            </div>
            <div className={styles.minMaxSection}>
              <span className={styles.minMaxText}>Max: </span>
              <span className={styles.minMaxValue}>{CommaSeperator(Number(maxAmount))}</span>
            </div>
          </div>
        </div>
        {/* <div className={styles.BankSectionOverlay}>
          <span className={styles.selectBankHeader}>Chọn ngân hàng</span>
          {bankErrorMessage && (
            <div className={styles.noBanksMsg}>{bankErrorMessage}</div>
          )}
          <div className={styles.BankSectionWrapper}>
            {banks && banks?.length > 0
              ? banks?.slice(0, 3).map((bank) => {
                  return (
                    <div
                      className={styles.bankCardWrappwr}
                      key={bank.id}
                      onClick={() => {
                        setBankId(bank.id);
                        setSelectedBank(bank);
                      }}
                    >
                      <div
                        className={`${styles.bankCard} ${
                          bankId == bank.id ? styles.selectedBank : ""
                        }`}
                      >
                        <div className={styles.bankCardContent}>
                          <span>{bank.User_name}</span>
                          <div>{bank.bank_name}</div>
                          <span
                            style={{
                              display: "inline-block",
                              wordBreak: "break-word",
                            }}
                          >
                            {bank.account_number}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })
              : banks == undefined && (
                  <div style={{ width: "100%" }}>
                    <MyLoader />
                  </div>
                )}

            <>
              {(() => {
                const arr = [];
                for (let i = 0; i <= 3; i++) {
                  arr.push(
                    banks && banks?.length < i && (
                      <div key={i}>
                        <AddBank setAddAccountState={setAddAccountState} />
                      </div>
                    )
                  );
                }
                return arr;
              })()}
            </>
          </div>
          <PointsConverter transferPoints={amount} withdraw={true} />
        </div> */}
      </div>
    </div>
  );
};

export default Step1;

