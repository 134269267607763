import React from "react";
import triangle from "../../assets/triangle.webp";
import { AiOutlineCloseCircle } from "react-icons/ai";
import styles from "./InputTooltipV2.module.css";
import CommaSeperator from "../CommaSeperator";
// import { useContext } from 'react';
// import UserContext from '../../helpers/Context/user-context';

const InputTooltipV2 = ({
  title,
  amount,
  setAmount,
  onAmountChange,
  withdraw,
  withdrawMinAmount,
  withdrawMaxAmount,
  disabled = false
}) => {
  
  let tooltipMinCheck = withdraw ? withdrawMinAmount : 150;
  let tooltipMaxCheck = withdraw ? withdrawMaxAmount : 90000;

  return (
    <div className={styles.depositInputWrapper}>
      <div className={styles.requiredLabel}>
        <span>* </span>
        {title}
      </div>
      <div className={styles.depositInputContainer}>
        <div className={styles.inputWrapper}>
          <span>đ</span>
          <input
            value={amount}
            type="number"
            placeholder={`${CommaSeperator(tooltipMinCheck)} ~ ${CommaSeperator(
              tooltipMaxCheck
            )}`}
            onChange={(e) => onAmountChange(e.target.value)}
            disabled={disabled}

          />

          {/* <div className={styles.tooltipWrapper}>
            {(amount && amount < tooltipMinCheck) ||
            amount > tooltipMaxCheck ? (
              <>
                <div className={styles.tooltip}>
                  <span>
                    {CommaSeperator(tooltipMinCheck)} ~{" "}
                    {CommaSeperator(tooltipMaxCheck)}
                  </span>
                  <div className={styles.arrowDown}>
                    <img src={triangle} alt="arrow" />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div> */}
        </div>
        {amount && (
          <AiOutlineCloseCircle
            onClick={() => {
              setAmount("");
            }}
            className={styles.cancelIcon}
          />
        )}
      </div>

      <span className={styles.inputAmoutRequired}>Vui lòng nhập số tiền</span>
    </div>
  );
};

export default InputTooltipV2;
