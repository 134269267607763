import React, { useEffect } from 'react';
import styles from "./QRCodeComponent.module.css";
import { GoDownload } from "react-icons/go";
import { singleTransactionStatusCheck } from '../../helpers/APIs/TransactionAPI';
import { useNavigate } from 'react-router-dom';
// import { CountdownTimer } from './CountdownTimer';

const QRCodeComponent = ({ base64String, QRData,  transactionId, setSingleTransactionModal }) => {
    const navigate = useNavigate();
    // Function to call your API
    const checkTransactionStatus = async () => {
        const res = await singleTransactionStatusCheck(transactionId);
        if (res.status) {
            if (setSingleTransactionModal) {
                setSingleTransactionModal(false);
            } else {
                navigate('/profile/transactions')
            }
        }
    };


    useEffect(() => {
        // Call the API on component mount
        checkTransactionStatus();
        // Set up an interval to call the API every 10 seconds
        const intervalId = setInterval(checkTransactionStatus, 10000);
        // Clear the interval on component unmount
        return () => clearInterval(intervalId);

    }, []); // Empty dependency array to ensure this runs once on mount

    const downloadQRCode = async (base64String, QRData) => {
        try {
            const response = await fetch(base64String);
            if (!response.ok) {
                throw new Error('Failed to fetch image');
            }
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `${QRData?.payment_method ? QRData?.payment_method + "_" + new Date().toISOString().replace(/[:.]/g, '_') : "QR" + "_" + new Date().toISOString().replace(/[:.]/g, '_')}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading QR code:', error);
        }
    };

    return (

        <div className={styles.qrImageContainer}>
         
            <div className={styles.QRWrapper}>
                <span className={styles.redText}>Vui lòng quét mã bên dưới</span>
                <img src={base64String} alt="QR Code" style={{
                    height: '100px', width: '100px'
                }} />
             

                <div
                    onClick={() => downloadQRCode(base64String, QRData)}
                    className={styles.download_button}
                >
                    <GoDownload /> Tải xuống QR
                </div>
            </div>

        </div>

    );
};




export default QRCodeComponent;