import style from "../../components/DetailsSection/DetailsSection.module.css";
import DividerLeft from "../../assets/HomeImages/DividerLeft.webp";
import DividerRight from "../../assets/HomeImages/DividerRight.webp";
import Promotion1 from "../../assets/HomeImages/Section3/Promotion1.webp";
import Promotion2 from "../../assets/HomeImages/Section3/Promotion2.webp";
import Promotion3 from "../../assets/HomeImages/Section3/Promotion3.webp";
import Promotion4 from "../../assets/HomeImages/Section3/Promotion4.webp";
import Promotion5 from "../../assets/HomeImages/Section3/Promotion5.webp";
import Promotion6 from "../../assets/HomeImages/Section3/Promotion6.webp";

export const DetailsSection = () => {
  return (
    <div className={style.wrapper}>
    <div className={style.container}>
        <div className={style.detailsTitle}>SV368 Nhà cái uy tín số 1 châu Á</div>
        <div className={style.detailsDescription}>
          SV368 Nhà cái uy tín số 1 châu Á SV368 là thương hiệu phát sóng trực
          tiếp đá gà hằng ngày có tỷ lệ kèo ăn cao, kết quả chính xác, cực kỳ hấp
          dẫn và gay cấn, với các trận gà đẳng cấp tiền lớn đầy kịch tính và máu
          lửa các chiến kê. Chúng tôi có phát sóng đá gà trực tiếp các thể loại
          khác nhau như : Đá gà đòn, đá gà cựa dao, đá gà thomo , đá gà VIỆT cựa
          sắt, tại đấu trường thomo, CPC1, CPC2, CPC3. SV368 trực tiếp các giải đá
          gà VIỆT, giải đá gà tre, luôn cam kết kết quả đá gà chính xác trung
          thực.
        </div>
    </div>
      
    </div>
  );
};

export default DetailsSection;
