import Modal from "@mui/material/Modal";
import styles from "../../components/PopupErrorModal/PopupErrorModal.module.css";
import errorIcon from "../../assets/Error.webp";
import successIcon from "../../assets/Success.webp";
import { useNavigate } from "react-router";

const PopupMessage = ({
    message,
    show,
    hideModal,
    error = true,
    modalClose,
    resetPassword = false
}) => {
    // invoiceFile,
    const navigate = useNavigate();


    return (
        <Modal open={show ? true : false} onClose={hideModal}>
            <div
                className={styles.modalOverlay}
                onClick={() => {
                    modalClose && hideModal();
                }}
            >
                <div
                    className={styles.loadingSection}
                // onClick={(e) => e.stopPropagation()}
                >
                    {error ? (
                        <img src={errorIcon} className={styles.errorImg} alt="errorImg" />
                    ) : (
                        <img
                            src={successIcon}
                            className={styles.errorImg}
                            alt="successImg"
                        />
                    )}
                    <div className={styles.messageText}>{message}</div>

                    <button className={styles.okButton} onClick={() => {
                        resetPassword ? navigate("/") : navigate("/transections");
                        hideModal();
                    }}>
                        OK
                    </button>



                </div>
            </div>
        </Modal>
    );
};

export default PopupMessage;
