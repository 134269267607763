import React, { useState } from 'react'
import styles from "./ResetPassword.module.css"
import { APIResetPassword } from '../../helpers/APIs/UserAPIs';
// import PopupMessage from '../../pages/Deposit/PopUpMessage';
import MyLoader from '../MyLoader';
import { FiEyeOff, FiEye } from "react-icons/fi";
import { FcCancel, FcOk } from "react-icons/fc";
import { IoClose } from "react-icons/io5";
import PopupMessage from '../../pages/Deposit/PopupMessage';

const ResetPassword = () => {
    const [oldPassword, setOldPassword] = useState("");
    const [oldPasswordHidden, setOldPasswordHidden] = useState(false);
    const [oldPasswordError, setOldPasswordError] = useState("");

    const [newPassword, setNewPassword] = useState("");
    const [newPasswordHidden, setNewPasswordHidden] = useState(false);

    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordHidden, setConfirmPasswordHidden] = useState(false);

    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [loading, setLoading] = useState(false)
    const [errorModal, setErrorModal] = useState(false)

    const onPasswordChange = (e) => {
        const { value } = e.target;
        // Remove non-alphanumeric characters
        const newValue = value.replace(/[^a-zA-Z0-9]/g, '');
        setNewPassword(newValue);
    };
    // upper case
    const checkIfHasUpperCaseChar = (value) => {
        return /[A-Z]/.test(value);
    };

    // lowe case
    const checkIfHasLowerCaseChar = (value) => {
        return /[a-z]/.test(value);
    };

    // special char
    const checkIfHasSpecialChar = (value) => {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,. <>\/?~]/;
        return specialChars.test(value);
    };

    // check number
    const checkIfHasNumber = (value) => {
        return /\d/.test(value);
    };

    // check length
    const checkifCorrectLength = (value) => {
        return value.length >= 8 && value.length <= 12;
    };

    const isValidPassword = (newPassword, confirmPassword) => {
        const hasLowerCaseChar = checkIfHasLowerCaseChar(newPassword);
        const hasNumber = checkIfHasNumber(newPassword);
        const hasUpperCaseChar = checkIfHasUpperCaseChar(newPassword);
        const hasSpecialChar = checkIfHasSpecialChar(newPassword);
        const hasCorrectLength = checkifCorrectLength(newPassword);
        const passwordsMatch = newPassword === confirmPassword;

        return (
            newPassword &&
            hasLowerCaseChar &&
            hasNumber &&
            hasUpperCaseChar &&
            !hasSpecialChar &&
            hasCorrectLength &&
            passwordsMatch
        );
    };
    const isPasswordValid = isValidPassword(newPassword, confirmPassword);
    const onSubmitHandler = async (e) => {
        e.preventDefault();

        if (isPasswordValid) {
            setConfirmPasswordError("")
            setLoading(true)
            const res = await APIResetPassword(oldPassword, newPassword);
            if (res === "PASSWORD_INCORRECT") {
                setOldPasswordError('Mật khẩu cũ không đúng, Vui lòng thử lại !')
            } else if (res.message === "PASSWORD_RESET_SUCCESS") {
                // hideModal()
                setOldPassword("")
                setNewPassword("")
                setConfirmPassword("")
                setErrorModal(true)
            } else {

            }
            setLoading(false)

        } else if (newPassword !== confirmPassword) {
            setConfirmPasswordError("Mật khẩu không trùng khớp.")
        }
    }
  return (
      <div className={styles.modalMainWrapper}>
          <div className={styles.modalWrapper}>
              <div className={styles.titleWrapper}>
                  <p>Thay đổi mật khẩu</p>
              </div>
              <div className={styles.smallContent}>
                  <span>Bạn vừa thực hiện yêu cầu đổi mật khẩu, Vui lòng thay đổi mật khẩu mới và lưu trữ nó cho lần đăng nhập tiếp theo.</span>
              </div>
              <form onSubmit={onSubmitHandler}>

                  <div className={styles.inputWrapperDiv}>
                      <div>
                          <div className={styles.requiredLabel}>
                              <span>*</span>Mật khẩu cũ
                          </div>
                          <div
                              className={`${styles.inputPasswordWrapper} ${styles.formInput}`}
                          >
                              <input
                                  type={oldPasswordHidden ? "text" : "password"}
                                  placeholder="Mật khẩu cũ"
                                  name="username"
                                  value={oldPassword}
                                  onChange={(e) => {
                                      setOldPassword(e.currentTarget.value);
                                  }}
                                  required
                              />
                              {oldPasswordHidden ? (
                                  <FiEye
                                      onClick={() => setOldPasswordHidden(false)}
                                      size={20}
                                      className={styles.passwordEye}
                                  />
                              ) : (
                                  <FiEyeOff
                                      onClick={() => setOldPasswordHidden(true)}
                                      size={20}
                                      className={styles.passwordEye}
                                  />
                              )}
                          </div>
                          {oldPasswordError && (
                              <div className={styles.error}>{oldPasswordError}</div>
                          )}
                      </div>
                      <div>
                          <div className={styles.requiredLabel}>
                              <span>*</span>Mật khẩu mới
                          </div>
                          <div
                              className={`${styles.inputPasswordWrapper} ${styles.formInput}`}
                          >
                              <input
                                  type={newPasswordHidden ? "text" : "password"}
                                  placeholder="Mật khẩu mới"
                                  name="username"
                                  value={newPassword}

                                  onChange={onPasswordChange}

                                  required
                              />
                              {newPasswordHidden ? (
                                  <FiEye
                                      onClick={() => setNewPasswordHidden(false)}
                                      size={20}
                                      className={styles.passwordEye}
                                  />
                              ) : (
                                  <FiEyeOff
                                      onClick={() => setNewPasswordHidden(true)}
                                      size={20}
                                      className={styles.passwordEye}
                                  />
                              )}
                          </div>
                          {newPassword ? (
                              <div style={{ marginTop: "7px" }}>
                                  <div className={styles.passwordValidation}>
                                      <span style={{ marginBottom: "0px" }}>
                                          {checkIfHasUpperCaseChar(newPassword) ? (
                                              <FcOk />
                                          ) : (
                                              <FcCancel />
                                          )}
                                      </span>
                                      <span
                                          className={
                                              checkIfHasUpperCaseChar(newPassword)
                                                  ? styles.success
                                                  : styles.error
                                          }
                                      >
                                          Mật khẩu phải có chữ IN HOA
                                      </span>
                                  </div>
                                  <div className={styles.passwordValidation}>
                                      <span style={{ marginBottom: "0px" }}>
                                          {checkIfHasLowerCaseChar(newPassword) ? (
                                              <FcOk />
                                          ) : (
                                              <FcCancel />
                                          )}
                                      </span>
                                      <span
                                          className={
                                              checkIfHasLowerCaseChar(newPassword)
                                                  ? styles.success
                                                  : styles.error
                                          }
                                      >
                                          Mật khẩu phải có chữ thường
                                      </span>
                                  </div>
                                  <div className={styles.passwordValidation}>
                                      <span style={{ marginBottom: "0px" }}>
                                          {checkIfHasNumber(newPassword) ? <FcOk /> : <FcCancel />}
                                      </span>
                                      <span
                                          className={
                                              checkIfHasNumber(newPassword)
                                                  ? styles.success
                                                  : styles.error
                                          }
                                      >
                                          Mật khẩu phải có số (0~ 9)
                                      </span>
                                  </div>
                                  <div className={styles.passwordValidation}>
                                      <span style={{ marginBottom: "0px" }}>
                                          {checkifCorrectLength(newPassword) ? (
                                              <FcOk />
                                          ) : (
                                              <FcCancel />
                                          )}
                                      </span>
                                      <span
                                          className={
                                              checkifCorrectLength(newPassword)
                                                  ? styles.success
                                                  : styles.error
                                          }
                                      >
                                          Mật khẩu cần dài hơn 8 ký tự và ngắn hơn 12 ký tự.
                                      </span>
                                  </div>
                                  <div className={styles.passwordValidation}>
                                      <span style={{ marginBottom: "0px" }}>
                                          {!checkIfHasSpecialChar(newPassword) ? (
                                              <FcOk />
                                          ) : (
                                              <FcCancel />
                                          )}
                                      </span>
                                      <span
                                          className={
                                              !checkIfHasSpecialChar(newPassword)
                                                  ? styles.success
                                                  : styles.error
                                          }
                                      >
                                          Không chứa ký tự đặc biệt, dấu cách
                                      </span>
                                  </div>
                              </div>
                          ) : (
                              ""
                          )}
                      </div>
                      <div>
                          <div className={styles.requiredLabel}>
                              <span>*</span>Xác nhận mật khẩu mới
                          </div>
                          <div
                              className={`${styles.inputPasswordWrapper} ${styles.formInput}`}
                          >
                              <input
                                  type={confirmPasswordHidden ? "text" : "password"}
                                  placeholder="Xác nhận mật khẩu mới"
                                  name="username"
                                  value={confirmPassword}
                                  onChange={(e) => {
                                      setConfirmPassword(e.currentTarget.value);
                                  }}
                                  required
                              />
                              {confirmPasswordHidden ? (
                                  <FiEye
                                      onClick={() => setConfirmPasswordHidden(false)}
                                      size={20}
                                      className={styles.passwordEye}
                                  />
                              ) : (
                                  <FiEyeOff
                                      onClick={() => setConfirmPasswordHidden(true)}
                                      size={20}
                                      className={styles.passwordEye}
                                  />
                              )}
                          </div>
                          {confirmPasswordError ? (
                              <span className={styles.error}>{confirmPasswordError}</span>
                          ) : (
                              ""
                          )}
                      </div>
                  </div>
                  {loading ? <MyLoader /> : <button className={`${styles.btn}`} type="submit"
                      disabled={isPasswordValid ? false : true}
                  >
                      Xác nhận
                  </button>}
              </form>
          </div>
          <PopupMessage
              message={'Mật khẩu đã được đặt lại thành công!'}
              show={errorModal}
              hideModal={() => setErrorModal(false)}
              error={false}
              resetPassword={true}
          />
      </div>
  )
}

export default ResetPassword
