import styles from "../Header1/Header1.module.css";
import SupportIcon from "../../../../assets/NewHomeIcons/Header1Icons/Support Icon.webp";
import PromotionIcon from "../../../../assets/NewHomeIcons/Header1Icons/Promotion icon.webp";
import MessengerIcon from "../../../../assets/NewHomeIcons/Header1Icons/Messenger Icon.webp";
import Withdraw from "../../../../assets/NewHomeIcons/Header1Icons/Withdraw.webp";
import Deposit from "../../../../assets/NewHomeIcons/Header1Icons/Deposit.webp";
import Profile from "../../../../assets/NewHomeIcons/Header1Icons/Profile.webp";
import { useNavigate } from "react-router-dom";

const linkArray = [
  { name: "Nạp tiền", link: "/profile/deposit", image: Withdraw },
  { name: "Rút tiền", link: "/profile/withdraw", image: Deposit },
  { name: "Khuyến mãi", link: "/promotions", image: PromotionIcon },
  { name: "Hỗ Trợ 24/7", link: "https://direct.lc.chat/13775445/", image: SupportIcon },
  { name: "Tài Khoản", link: "/profile", image: Profile },
];
export const Header1 = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.header1}>
      {linkArray.map((item) => (
        <span
        key={item.name}
          className={styles.header1_links}
          onClick={() => {
            if (item.name === "Hỗ Trợ 24/7") {
              window.open('https://direct.lc.chat/13775445/', '_blank')
            }else{
              navigate(item.link)
            }
            
          }}
        >
          <img
            className={styles.header1_icons}
            src={item.image}
            alt={item.name}
          />
          {item.name}
        </span>
      ))}
    </div>
  );
};

export default Header1;
