import React from "react";
import styles from "./Home.module.css";
import TopBanner from "../../components/HomeComponents/TopBannerSlider/TopBanner";
import announcement from "../../assets/HomeImages/Announcement.webp";
import GameSection from "../../components/HomeComponents/GameSection/GameSection";
import CameraSection from "../../components/HomeComponents/CameraSection/CameraSection";
import AnnouncementPopup from "../../components/HomeComponents/AnnouncementPopup/AnnouncementPopup";
import { useState } from "react";
import { useEffect } from "react";
import { APICheckAnnouncement } from "../../helpers/APIs/AnnouncementAPI";
import { useContext } from "react";
import UserContext from "../../helpers/Context/user-context";
import useOpenPlayGameModal from "../../helpers/useOpenPlayGameModal";
import DagaGamesSection from "../../components/DagaGamesSection/DagaGamesSection";
import ArticleSection from "../../components/ArticleSection/ArticleSection";
import HottestSection from "../../components/HottestSection/HottestSection";
import PopularSection from "../../components/PopularSection/PopularSection";
import PromotionSection from "../../components/PromotionSection/PromotionSection";
import DetailsSection from "../../components/DetailsSection/DetailsSection";
import { useLocation } from "react-router-dom";
import ResetPassword from "../ResetPasswordModal/ResetPassword";

const Home = () => {
  const [announcementState, setAnnouncementState] = useState();
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const [gameItems, setGameItems] = useState();
  const [playGameState, setPlayGameState] = useState(false);
  const [gamesListBkg, setGamesListBkg] = useState([]);
  const [singleGameModal, setSingleGameModal] = useState();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [daga, setDaga] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const location = useLocation();
  const { showModal } = location.state || {};
  const ctx = useContext(UserContext);
  const user = ctx.user;
  const userInfo = ctx.userInfo;

  useEffect(() => {
    // Update resetPasswordModal state based on showModal value
    if (showModal) {
      // If showModal is true, set resetPasswordModal to true and other states to false
      setResetPasswordModal(true);
    } else {
      // If showModal is false or undefined, set resetPasswordModal to false
      setResetPasswordModal(false);
    }
  }, [showModal]);


  useEffect(() => {
    setGameItems(ctx.categoryGamesContext?.games);
  });
  useEffect(() => {
    if (gameItems?.length > 0) {
      const flattenedArray = gameItems?.reduce((acc, item) => {
        const items = item?.game_items?.map((image) => ({
          common: item?.common,
          image,
          backgroundColor: bgColor[image.id], // Assuming bgColor is defined elsewhere
        }));
        return acc.concat(items);
      }, []);

      setGamesListBkg(flattenedArray);
      setIsDataLoaded(true);
    }
  }, [gameItems]);
 
  // get announcement API
  const getAnnoucementDetail = async () => {
    const res = await APICheckAnnouncement();
    if (Object.keys(res)?.length > 0) {
      setAnnouncementState(res);
      setShowAnnouncement(res ? true : false);
    }
  };

  // get announcement
  useEffect(() => {
    getAnnoucementDetail();
  }, []);
  const bgColor = [
    "#e6194b ",
    "#F21F2B ",
    "#17D9A7 ",
    "#17D9A7 ",
    "#019DE9 ",
    "#F3A90A ",
    "#AC16F5 ",
    "#911eb4",
    "#F61B64 ",
    "#F3A90A ",
    "#F21F2B",
    "#019DE9 ",
    "#F61B64",
    "#F86801 ",
    "#F8D701",
    "#AC16F5 ",
    "#17D9A7",
    "#85E901 ",
    "#F3A90A ",
    "#F21F2B",
    "#019DE9 ",
    "#F61B64",
    "#F86801 ",
    "#F8D701",
    "#AC16F5 ",
    "#17D9A7",
    "#F86801 ",
    // "#F3A90A ",
    "#F8D701",
    "#F3A90A ",
    "#F61B64",
    "#F86801 ",
  ];
  return (
    <>
      <div>
        <TopBanner />
        {/* <div className={styles.marqueeWrapper}>
          <img src={announcement} alt="announcement" />
          <marquee>
            Ngân hàng TCB TRAN NGOC CUONG 19050104271012 & MB NGUYEN THI PHUONG
            NGA 0937602576 sẽ ngưng sử dụng để nạp tiền từ 00:00:00 ngày
            17/1/2023 (GMT+7). Quý khách vui lòng không thực hiện giao dịch gửi
            tiền với ngân hàng này.
          </marquee>
        </div> */}

        <div className={styles.backgroundSetter}>
          <div className={styles.gameCategorySectionWrapper}>
            <HottestSection />
            <PopularSection />
            <PromotionSection />
            <DetailsSection />
            {/*
            <div className={styles.categorySlider}>
              <AttentionSeeker effect="bounce" duration="2000">
                <GameItemSlider />
              </AttentionSeeker> 
            </div>
          */}

            {/* <DagaGamesSection
              gamesListBkg={gamesListBkg}
              setPlayGameState={setPlayGameState}
              setSingleGameModal={setSingleGameModal}
              playGameState={playGameState}
              singleGameModal={singleGameModal}
              gameItems={gameItems}
              daga={daga}
              setDaga={setDaga}
            /> */}
            {/* <div
            //  style={{ padding: "0 15%" }}
            >
              {user && userInfo && <UserSection />}
            </div> */}

            {/* <ArticleSection />
            <GameSection daga={daga} setDaga={setDaga} /> */}

            {/* <DagaSection />
          

          <MobileSection /> */}

            {/*
            <div className={styles.movingBanner}>
              <img src={movingBanner} alt="movingbanner" />
            </div>
          */}
          </div>
          {/*<CameraSection />*/}
        </div>
      </div>

      {showAnnouncement && (
        <AnnouncementPopup
          show={showAnnouncement}
          hideModal={() => setShowAnnouncement(false)}
          announcement={announcementState}
        />
      )}
      <ResetPassword
        show={resetPasswordModal ? true : false}
        hideModal={() => setResetPasswordModal(false)}
        showModal={showModal}
      />
    </>
  );
};

export default Home;
