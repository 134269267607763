import { useContext, useEffect, useState } from "react";
import styles from "./RightSection.module.css";
import PointsConverter from "../../../components/PointsConverter/PointsConverter";
import PopupErrorModal from "../../../components/PopupErrorModal/PopupErrorModal";
import {
  APIMakeDepositRequest,
  depositAllowed,
} from "../../../helpers/APIs/TransactionAPI";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../helpers/Context/user-context";
import MyLoader from "../../../components/MyLoader";
import InputTooltip from "../../../components/InputTooltip/InputTooltip";
import activeBank from "../../../assets/ProfilePage/ActiveBankManagement.webp";
import CommaSeperator from "../../../components/CommaSeperator";
import { Select, MenuItem, styled } from '@mui/material';
import UrlContext from "../../../helpers/Context/url-context";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const RightSection = ({
  amount,
  setAmount,
  selectedBank,
  setSelectedBank,
  companyBanks,
  errorMessage,
  setErrorMessage,
  setData,
  setActiveStep,
  bankListLoading,
  setLoading,
  loading,
  activeStep,
  setQRData,
  paymentProviders,
  selectedProviderDetail,
  setSelectedProviderDetail
}) => {
  const [isDeposit, setIsDeposit] = useState(true);
  const [errorModal, setErrorModal] = useState(false);
  const [isDepositLoader, setIsDepositLoader] = useState(false);
  const [redirectLink, setRedirectLink] = useState("/");
  const [showOkBtn, setShowOkBtn] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [menuItem, setMenuItem] = useState('');
  const { baseUrl } = useContext(UrlContext);

  const navigate = useNavigate();
  let minAmount = 150;
  let maxAmount = 150000;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    // Cleanup function to remove the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);




  // check deposit block API
  // useEffect(() => {
  //   checkDiposetAllow();
  // }, []);


  // deposit blocked API
  // const checkDiposetAllow = async () => {
  //   try {
  //     setIsDepositLoader(true);
  //     const res = await depositAllowed();
  //     if (res.status === 200) {
  //       setIsDeposit(res.data.status);
  //       setIsDepositLoader(false);
  //       setShowOkBtn(true)
  //     }
  //   } catch (e) {
  //     setIsDepositLoader(false);
  //   }
  // };

  // useEffect(() => {
  //   if (!isDeposit) {
  //     setErrorModal(true);
  //     setErrorMessage(
  //       "Tính năng nạp tiền của bạn đã bị khoá, vui lòng liên hệ CS để được hỗ trợ."
  //     );
  //   }
  // }, [isDeposit]);



  // amount onChange
  const onDepositAmountChange = (val) => {
    if (isNaN(val) || val.includes('.')) {
      setAmount(val);
      setErrorMessage("Vui lòng chỉ nhập số không có giá trị thập phân")
    }
    else if (!isNaN(val)) {
      setAmount(val);
      setErrorMessage("")
    }
  };
  useEffect(() => {
    setMenuItem('')
  }, [selectedProviderDetail])
  const handleChange = (event) => {
    setMenuItem(event.target.value);
    const selectedBank = companyBanks.find((bank) => bank.id === event.target.value);
    setSelectedBank(selectedBank);
  };
  // Custom styled arrow icon component
  const WhiteArrowDropDownIcon = styled(ArrowDropDownIcon)({
    color: 'white',
  });

  // no banks
  const noBanks = () => {
    return (
      <div className={styles.loaderWrapper}>
        <MyLoader />
      </div>
    );
  };


  // company section
  const companyBankSection = () => {


    return (
      // <Select value={selectedBank?.id || menuItem} onChange={handleChange} style={{ width: '100%', color: 'white', border: '1px solid white' }}
      //   IconComponent={WhiteArrowDropDownIcon} // Use the custom arrow icon component
      // >
      //   {companyBanks?.sort((a, b) => a.sort_order - b.sort_order)?.map((bank) => (
      //     <MenuItem key={bank.id} value={bank?.id}>
      //       {bank.bank_name}
      //     </MenuItem>
      //   ))}
      // </Select>
      <div className={styles.methodCardSection}>

        {companyBanks?.sort((a, b) => a.sort_order - b.sort_order)?.map((bank) => (
          <div key={bank.id}
            className={`${styles.methodCard} ${bank.id == selectedBank?.id ? styles.activeMethod : ''}`}
            style={{ cursor: activeStep === 'step2' ? 'not-allowed' : 'pointer' }}
            onClick={() => setSelectedBank(bank)} >

            <div className={styles.imageContainer}>
              <img src={bank?.bank_image} alt="bank" />
            </div>
            <span>{bank.bank_name}</span>
          </div>
        ))}
      </div>  
    )

  };


  const sugestedAmount = [
    { label: "150", value: "150" },
    { label: "300", value: "300" },
    { label: "900", value: "900" },
    { label: "3 Triệu", value: "3000" },
    { label: "30 Triệu", value: "30000" },
  ];


  // deposit submit
  const sendDepositRequest = async () => {
    if (amount) {
      const payment_method = selectedProviderDetail?.id;
      const bank_id = selectedBank?.id;
      const payment_method_code = selectedProviderDetail?.payment_method_code;
      const x = await APIMakeDepositRequest(amount,
        bank_id,
        payment_method,
        payment_method_code

      );
      if (x === "YOU_HAVE_PENDING_TRANSACTION") {
        setErrorModal(true);
        setErrorMessage(
          "Bạn có yêu cầu đang chờ xử lý. Bạn không thể tạo yêu cầu mới khi đang có yêu cầu chờ xử lý. Vui lòng yêu cầu lại sau."
        );
        setShowOkBtn(true)
        setRedirectLink("/profile/transactions");
      } else if (x === 'FAILD_TO_GET_QR') {
        setErrorModal(true);
        setErrorMessage(
          "Ngân hàng quý khách vừa chọn hiện đang bảo trì. Vui lòng lựa chọn ngân hàng khác!"
        );
        setRedirectLink("/");
        setShowOkBtn(true)

      } else if (x === 'WRONG_PAYMENT_METHOD') {
        setErrorModal(true);
        setErrorMessage(
          "Phương thức thanh toán bạn vừa chọn hiện đang được bảo trì. Vui lòng chọn phương thức thanh toán khác!"
        );
        setShowOkBtn(true)

        setRedirectLink("/");
      } else if (x === 'BANK_NOT_SUPPORTED') {
        setErrorModal(true);
        setErrorMessage(
          "Ngân hàng quý khách vừa chọn hiện đang bảo trì. Vui lòng lựa chọn ngân hàng khác!"
        );
        setShowOkBtn(true)

        setRedirectLink("/");
      } else if (x === 'NETWORK_ERROR') {
        setErrorModal(true);
        setErrorMessage(
          "Đã có lỗi trong quá trình nạp tiền. Vui lòng liên hệ Chăm sóc khách hàng"
        );
        setShowOkBtn(true)
        setRedirectLink("/");
      } else if (!x) {
        setErrorModal(true);
        setErrorMessage(
          "Đã có lỗi trong quá trình nạp tiền. Vui lòng liên hệ Chăm sóc khách hàng"
        );
        setShowOkBtn(true)
      } else {
        return x;
      }

    }
    return null;
  };



  //submit func
  const onDepositSubmitClicked = async (e) => {
    e.preventDefault();
    setLoading(true);
    const deposit = await sendDepositRequest();
    if (deposit?.status) {
      if (deposit.data?.message === 'AUTO_APPROVED') {
        navigate("/")
      } else {
        // if (!ctx?.userInfo?.user_id && !ctx?.userInfo?.user_name) {
        //   localStorage.setItem("initialDeposit", myDate);
        // }

        if (deposit?.is_redirect) {
          setActiveStep("redirectPage");
          setData(deposit);
        } else {
          setActiveStep("step2");
          setQRData(deposit);
        }
      }
    }
    setLoading(false);
  };


  // next to continue
  const onContinueClicked = (e) => {
    if (
      amount &&
      !(amount < minAmount) &&
      !(amount > maxAmount) &&
      !amount.includes(".")
    ) {
      onDepositSubmitClicked(e);
    } else {
      setErrorMessage("Vui lòng nhập số tiền");
    }
    // handleNext();
  };

  return (
    <div className={styles.depositSectionOverlay}>
      <div className={styles.depositSectionWrapper}>
        <InputTooltip
          disabled={activeStep === 'step2'}
          errorMessage={errorMessage}
          amount={amount}
          setAmount={setAmount}
          title={"Số điểm nạp"}
          onAmountChange={onDepositAmountChange}
        />
        <div className={styles.errorMsg}>{errorMessage}</div>
        <PointsConverter transferPoints={amount * 1000}
          deposit={true}
          text="Thực tế" />
        {amount && (     
        <div className={styles.depositButtonSection}>
          {sugestedAmount.map((item) => (
            <button
              onClick={() => onDepositAmountChange(item.value)}
              className={styles.depositButton}
              key={item.label}
              disabled={activeStep === 'step2'}

            >
              {item.label}
            </button>
          ))}
        </div> )}

{/* 
        <div className={styles.bankSectionContainer} style={{ marginTop: '15px' }}>
          <div className={styles.bankSectionTitle}>Các kênh thanh toán</div>
          <div className={styles.companySelection}>
            {bankListLoading ? <MyLoader /> : paymentProviders && paymentProviders?.sort((a, b) => a.sorting_order - b.sorting_order)?.map((item, index) => {
              return (<div
                key={item?.payment_method_code}
                onClick={activeStep === 'step2' || loading ? null : () => setSelectedProviderDetail(item)}
                className={`${styles.methodCard} ${item.payment_method_code == selectedProviderDetail?.payment_method_code ? styles.activeMethod : ''}`}
                style={{ cursor: activeStep === 'step2' ? 'not-allowed' : 'pointer' }}
              >
                <img className="imgStyle" src={`${item.payment_method_image}`} alt="bank" />
                <div>{item.payment_method_name}</div>
              </div>)
            }

            )}
          </div>
        </div> */}

        {
          <div className={styles.companyBankSection}>
            <div style={{ width: '100%', margin: '10px 0' }}>

              <div className={styles.companyBankContainer}>
                {companyBanks?.length
                  ? companyBankSection()
                  : bankListLoading
                    ? noBanks()
                    : errorMessage && (
                      <p className={styles.errorMsg}>
                        {errorMessage}
                      </p>
                    )}
              </div>
            </div>

          </div>
        }
        

        {loading ? <div className={styles.loader}>
          <MyLoader size={30} />
        </div> :     <button
          className={`${styles.submitButton} ${
              !amount ||
                amount < minAmount ||
                amount > maxAmount ||
            amount.includes(".")
              ? styles.disabled
              : ""
          }`}
            onClick={activeStep === 'step2' || loading ? null : (e) => onContinueClicked(e)}
          disabled={isDepositLoader || !isDeposit || loading}
            style={{ cursor: activeStep === 'step2' || loading ? 'not-allowed' : 'pointer' }}

        >
          Tiếp theo
        </button>}

        {errorModal && (
          <PopupErrorModal
            show={errorModal}
            setErrorMessage={setErrorMessage}
            setErrorModal={setErrorModal}
            message={errorMessage}
            hideModal={() => {
              setErrorModal(false);
              setErrorMessage(null);
            }}
            selectedBankId={selectedBank.id}
            redirectLink={redirectLink}
            amount={amount}
            showOkBtn={showOkBtn}
          />
        )}
      </div>
    </div>
  );
};

export default RightSection;
