import Modal from '@mui/material/Modal';
import styles from "./RegisterPopupModal.module.css";
import CustomerSupportAnimatedItem from '../../../components/CustomerSupportAnimatedItem/CustomerSupportAnimatedItem';
import Success from '../../../assets/Success.webp';
import registerError from '../../../assets/registerError.webp';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import UserContext from '../../../helpers/Context/user-context';

const RegisterPopupModal = ({ show, hideModal, message, x, isErrorMessage }) => {

  const ctx = useContext(UserContext);
  const navigate = useNavigate();

  const goDeposit = () => {
    ctx.setUser(x);
    navigate('/profile/deposit')
  }

  return (
    <Modal open={show}>
      <div className={styles.modalOverlay} onClick={isErrorMessage && hideModal}>
        <div className={isErrorMessage ? styles.errorContainer : styles.successContainer} onClick={(e) => e.stopPropagation()}>
          {isErrorMessage ?
            <div className={styles.imageWrapper}>
              <img src={registerError} className={styles.image} alt='errorImg'/>
            </div>
            :
            <div className={styles.imageWrapper}>
              <img src={Success} className={styles.image} alt='successImg'/>
            </div>}
          <div className={styles.modalContentWrapper}>
            <div style={{ fontSize: '20px' }}>{message}</div>
            {!isErrorMessage && <div className={styles.depositButton} onClick={goDeposit}>Nạp Tiền</div>}
            <CustomerSupportAnimatedItem />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default RegisterPopupModal