
import styles from "./Header.module.css";
import SecondaryHeader from "./HeaderComponents/SecondaryHeader/SecondaryHeader";
import Header1 from "./HeaderComponents/Header1/Header1";

const Header = () => {
  const secondHeaderData=[{id:1,title:'SÒNG BÀI'},{id:2,title:'THỂ THAO'},{id:3,title:'XỔ SỐ'},{id:4,title:'GAME BÀI'},{id:5,title:'BÁN CÁ'}]
  return (
    <div className={styles.headerMain} style={{position:'relative'}}>
      <Header1 />
      <SecondaryHeader  secondHeaderData={secondHeaderData}/>
    </div>
  );
};

export default Header;
