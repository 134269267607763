import { useState } from "react";
import UrlContext from "./url-context.js";

const BaseUrlProvider = (props) => {
    const [baseUrl, setBaseUrl] = useState(null);
  

    const userContext = {
        baseUrl,
        setBaseUrl
    };

    return (
        <UrlContext.Provider value={userContext}>
            {props.children}
        </UrlContext.Provider>
    )
}

export default BaseUrlProvider;