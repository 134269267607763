import { Modal } from "@mui/material";
import styles from "./AddAccountModal.module.css";
import { useState } from "react";
import vietnamBankArray from "../../data/vietnamBankArray";
import { useEffect } from "react";
import { AddAccountAPI, bankListAPI, getAllBanks } from "../../helpers/APIs/BankAPIs";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { CiCreditCard1 } from "react-icons/ci";
import MyLoader from "../MyLoader";

const AddAccountModal = ({ show, hideModal, maxAccounts }) => {
  const [accNumber, setAccNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [userName, setUserName] = useState("");
  const [bankExist, setBankExist] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [error, setError] = useState("");
  const [apiError, setApiError] = useState("");
  const [loader, setLoader] = useState(false);
  const [banks, setBanks] = useState();

  useEffect(() => {
    bankList();
    getAllBanksList();

  }, [show]);

  // get bank list Api
  const bankList = async () => {
    const allBanks = await bankListAPI();
    if (allBanks?.response?.length) {
      setUserName(allBanks?.response[0].User_name);
      setBankExist(true);
    }
  };
  // get bank list Api
  const getAllBanksList = async () => {
    const allBanks = await getAllBanks();
    if (allBanks?.length) {
      setBanks(allBanks);
    }
  };
  // submit func
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (accNumber.length >= 3) {
      // setIsButtonDisabled(true);
      if (bankName && accNumber && userName && checkIfOnlyCapital(userName) &&
        checkIfOnlyNumbers(accNumber)) {
        setLoader(true);
        const x = await AddAccountAPI(bankName, accNumber, userName);
        if (x.data.message) {
          setApiError(`Mỗi tài khoản chỉ có thể thêm ${maxAccounts} ngân hàng duy nhất. Để thêm tài khoản ngân hàng khác, Vui lòng liên hệ đội ngũ Chăm Sóc Khách Hàng`);
        } else {
          closeModal();
        }
        setLoader(false);

      }
    }
  };

  // check if only capital
  const checkIfOnlyCapital = (value) => {
    if (value) {
      return /^[A-Z]*$/.test(value.replace(/\s+/g, ""));
    }
  };

  // check if only numbers
  const checkIfOnlyNumbers = (value) => {
    return /^[0-9]+$/.test(value);
  };

  // reset all
  const closeModal = () => {
    hideModal();
    setUserName("");
    setAccNumber("");
    setBankName("");
    setBankExist("");
    setError("");
    setApiError("");
    setLoader(false);
    setIsButtonDisabled(false);
  };
  const handleValidateUserName = (value) => {
    // Initialize an empty string to build the valid username
    let validatedUserName = '';

    // Iterate over each character in the input value
    for (let char of value) {
      // Check if the character is an alphabet or a space
      if (char.match(/[a-zA-Z ]/)) {
        // If valid, add the uppercase version of the character to the validatedUserName
        validatedUserName += char.toUpperCase();
      }
    }

    // Set the validated and transformed userName to the state
    setUserName(validatedUserName);
  };
  return (
    <Modal open={show ? true : false} onClose={hideModal}>
      <div className={styles.modalOverlay} onClick={(e) => closeModal()}>
        <div
          className={styles.modalWrapper}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={styles.modalTitle}>Thêm Tài Khoản Ngân Hàng</div>
          <form
            onSubmit={handleSubmit}
            className={styles.addAccountFormWrapper}
          >
            <div className={styles.inputContainer}>
              <div className={styles.requiredLabel}>
                <span>*</span>Tên Tài Khoản
              </div>
              <div
                className={`${styles.helptext} ${
                  checkIfOnlyCapital(userName) ? "" : styles.danger
                }`}
              >
                Tên tài khoản viết IN HOA, không dấu, không có số.
              </div>
              <div className={styles.inputItem}>
                <input
                  style={{ border: "none" }}
                  readOnly={bankExist}
                  placeholder="＊ Tên tài khoản"
                  required
                  value={userName}
                  onChange={(e) => { handleValidateUserName(e.currentTarget.value) }}

                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.requiredLabel}>
                <span>*</span>Ngân Hàng
              </div>
              <div className={styles.addBankSelectWrapper}>
                <select
                  required
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                >
                  <option value="" disabled >
                    Vui lòng Chọn ngân hàng
                  </option>
                  {banks?.map((val, index) => (
                    <option key={index}>{val}</option>
                  ))}
                </select>
                <span>
                  <MdOutlineKeyboardArrowDown color="#DCF6FE" />
                </span>
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.requiredLabel}>
                <span>*</span>Số Tài Khoản
              </div>
              <div className={styles.inputItem}>
                <CiCreditCard1 size={25} style={{ color: "#DCF6FE" }} />
                <input
                  style={{ border: "none" }}
                  placeholder="＊ Vui lòng nhập số tài khoản"
                  required
                  value={accNumber}
                  onChange={(e) => {
                    setAccNumber(e.target.value);
                  }}
                  minLength={1}
                  maxLength={30}
                  type='number'
                />
              </div>
              {accNumber && !checkIfOnlyNumbers(accNumber) ? (
                <div className={styles.errorMsg}>Vui lòng chỉ nhập số</div>
              ) : (
                ""
              )}
              {apiError && <div className={styles.errorMsg}>{apiError}</div>}
            </div>
            {loader && <MyLoader />}
            <div className={styles.formButton}>
              <button
                type="submit"
                disabled={
                  isButtonDisabled ||
                  !checkIfOnlyCapital(userName) ||
                  !checkIfOnlyNumbers(accNumber)
                }
                className={`${styles.button} ${
                  !checkIfOnlyCapital(userName) ||
                  !checkIfOnlyNumbers(accNumber)
                    ? styles.disabled
                    : ""
                } `}
              >
                Xác nhận
              </button>
              <button
                className={`${styles.button}`}
                onClick={() => closeModal()}
              >
                Hủy
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AddAccountModal;
