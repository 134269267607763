import style from "../../components/PopularSection/PopularSection.module.css";
import DividerLeft from "../../assets/HomeImages/DividerLeft.webp";
import DividerRight from "../../assets/HomeImages/DividerRight.webp";
import Game3 from "../../assets/HomeImages/Section2/Game3.webp";
import Game4 from "../../assets/HomeImages/Section2/Game4.webp";
import Game5 from "../../assets/HomeImages/Section2/Game5.webp";
import Game6 from "../../assets/HomeImages/Section2/Game6.webp";
import Game7 from "../../assets/HomeImages/Section2/Game7.webp";
import Game8 from "../../assets/HomeImages/Section2/Game8.webp";
import { useContext } from "react";
import UserContext from "../../helpers/Context/user-context";

export const PopularSection = () => {
  const ctx = useContext(UserContext);

  const referral = ctx.refCode && ctx.refCode;
  const games = [
    { game_id: 1, image: Game3 },
    { game_id: 2, image: Game4 },
    { game_id: 3, image: Game5 },
    { game_id: 4, image: Game6 },
    { game_id: 5, image: Game7 },
    { game_id: 6, image: Game8 },
  ];

  const gameImgClick = () => {
    window.open(
      ctx.refCode
        ? `https://${referral}.sv368vn.today/register.html`
        : `https://www.sv368.bio/register`,
      "_blank"
    );
  };
  return (
    <div className={style.wrapper}>
      <div className={style.sectionTitle}>
        <div>
        <img
          className={style.imgDivider}
          src={DividerLeft}
          alt={`DividerLeft`}
        />
        </div>
        <div className={style.title}>Phổ Biến</div>
      <div><img
          className={style.imgDivider}
          src={DividerRight}
          alt={`DividerRight`}
        />
        </div>
      </div>
      <div className={style.sectionContent}>
        <div className={style.gameList}>
          {games?.map((game, index) => {
            return (
              <div key={game?.id} className={style.gameItem} >
              <img
                
                className={style.gameImage}
                src={game?.image}
                alt={`Game${game?.id}`}
                onClick={gameImgClick}
              />
              </div>
            );
          })}
        </div>
        {/* <div className={style.gameList}>
          {games.slice(3, 6)?.map((game, index) => {
            return <img key={game?.id} className={style.gameImage} src={game?.image} alt={`Game${game?.id}`} onClick={gameImgClick} />
          })}
                  </div> */}
      </div>
    </div>
  );
};

export default PopularSection;
